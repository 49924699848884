<template>
  <DatasetManager />
</template>

<script>
import DatasetManager from '@/components/DatasetManager.vue';

export default {
  components: {
    DatasetManager
  }
};
</script>
